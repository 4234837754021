import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../layout"
import SEO from "../../seo"
import styled from "styled-components"
import Image from "gatsby-image"
import { forward, backward } from "../../../utils/imageUpload"
import bgColors from "./bgColors"
import BodyWrapper from "../../BodyWrapper"
import DiscoverProduct from "./DiscoverProduct"
export const query = graphql`
  query SINGLE_COLLECTION($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      title
      handle
      products {
        title
        description
        handle
        id
        shopifyId
        productType
        tags
        images {
          localFile {
            childImageSharp {
              fluid {
                src
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const CollectionPage = ({ data }) => {
  const handleCollection = data.shopifyCollection

  const productArr = data.shopifyCollection.products
  const [current, setcurrent] = useState(0)
  const [productImgIndex, setproductImgIndex] = useState(1)
  const [bgColor, setbgColor] = useState({
    color: "var(--beige)",
    titleColor: "var(--pink)",
  })

  useEffect(() => {
    setbgColor(setBackground(bgColors, productArr, current))
  }, [current])

  const setBackground = (colorArr, products, curr) => {
    const match = colorArr.filter(el => {
      return el.title.toLowerCase() === products[curr].title.toLowerCase()
    })

    if (match[0]) return match[0]
    else return { color: "var(--beige)", titleColor: "var(--pink)" }
  }

  const setIndex = direction => {
    if (direction === "back" && current > 0) {
      setcurrent(current - 1)
    } else if (direction === "forward" && current < productArr.length - 1) {
      setcurrent(current + 1)
    }
  }

  const changeImg = bool => {
    if (bool) {
      setproductImgIndex(0)
    } else {
      setproductImgIndex(1)
    }
  }

  return (
    <Layout>
      <SEO title={productArr?.[current]?.title} />
      {/* <BodyWrapper bg={"var(--beige)"}> */}
      <Wrapper bgColor={bgColor} style={{ padding: "0" }}>
        <div className="active-link">
          <Link to="products/breakfast" activeClassName="activeLink">
            Breakfast
          </Link>
          <Link to="products/cookies" activeClassName="activeLink">
            Cookies
          </Link>
          <Link to="products/desserts" activeClassName="activeLink">
            Desserts
          </Link>
        </div>
        <div
          className="container"
          style={{
            marginTop: "50px",
          }}
        >
          {/* <Button desktop onClick={() => setIndex("back")}>
              <img width="15px" src={backward} alt="" />{" "}
            </Button> */}
          <div
            style={{
              width: "100%",
            }}
          >
            {productArr.map(current => {
              return (
                <DiscoverProduct
                  handleCollection={handleCollection}
                  currentProduct={current}
                  productArr={productArr}
                />
              )
            })}
          </div>

          {/* <Button desktop onClick={() => setIndex("forward")}>
              <img width="15px" src={forward} alt="" />{" "}
            </Button> */}
        </div>
      </Wrapper>
      {/* </BodyWrapper> */}
    </Layout>
  )
}

const Wrapper = styled.div`
  background-color: var(--beige);

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  align-items: center;
  @media (min-width: 1500px) {
    padding: 0;
  }

  .container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100vw;
    @media (min-width: 1500px) {
      width: 100%;
    }
  }

  .active-link {
    margin-top: 50px;

    .activeLink {
      background: var(--darkBlue);
      color: var(--white);
    }
    a {
      text-decoration: none;
      margin: 0 5px;
      border: 1px solid var(--darkBlue);
      padding: 8px 25px;
      border-radius: 20px;
      background: transparent;
      color: var(--darkBlue);
      text-transform: uppercase;
      font-weight: bold;

      :hover {
        background: var(--darkBlue);
        color: var(--white);
      }
    }
  }

  @media (max-width: 678px) {
    .active-link {
      width: 100%;
      display: flex;
      justify-content: center;
      a {
        padding: 8px 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0;

    .container {
      width: 100%;
    }
  }

  @media (min-width: 1500px) {
    min-height: 75vh;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

const ImgContainer = styled.div`
  width: 500px;
  height: 500px;
  @media (min-width: 1500px) {
    height: 350px;
    width: 350px;
  }

  @media (max-width: 1024px) {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Button = styled.div`
  margin: 0 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid var(--darkBlue);

  :hover {
    cursor: pointer;
  }

  display: ${props => (props.mobile ? "none" : "")};

  @media (max-width: 1024px) {
    display: ${props => (props.desktop ? "none" : "inherit")};
  }
`

const ProductContainer = styled.div`
  // width: 100%;
  display: flex;
  // justify-content: space-evenly;
  margin: 50px 0;
  // align-items: flex-start;

  .assets-div {
    // border: 1px solid red;
    display: flex;
    align-items: center;

    justify-content: center;
  }

  .info-div {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // border: 1px solid red;
    @media (min-width: 1500px) {
      /* width: 400px; */
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .price {
    color: var(--pink);
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .description {
    color: var(--darkBlue);
    font-weight: 500;
    margin: 2em 0;
  }

  .btn-div {
    display: flex;
    width: 45%;
    backround: white;
    border-radius: 20px;
    color: var(--pink);
    border: 2px solid var(--pink);
    align-items: center;
    font-weight: bolder;
    justify-content: center;

    p {
      margin: 0;
    }

    button {
      width: 40%;
      padding: 15px 12px;
      background: transparent;
      color: var(--pink);
      border: none;
      font-weight: bolder;
    }
  }

  .button-container-product {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    button {
      width: 150px;

      font-size: 0.9em;
      font-weight: bolder;
      text-transform: uppercase;
      border: none;
      border-radius: 20px;
      padding: 15px 25px;

      margin: 10px 0;
    }
  }

  .buttonIcon-div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .description-icons-div {
    display: flex;

    justify-content: space-evenly;
    width: 100%;
    margin-right: 10px;
  }

  .description-icons {
    width: 100%;
    height: 110px;
    // margin: 0 10px;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;

      img {
        object-fit: contain !important;
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    .assets-div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .gatsby-image-wrapper {
      // height: 100%;
      width: 100%;
    }
    .info-div {
      margin: 0 auto;
      width: 90%;
    }

    .description-icons-div {
      justify-content: space-evenly;
      margin-right: 0;
    }
    .buttonIcon-div {
      flex-direction: column;
    }

    .button-container-product {
      align-items: center;
      margin-top: 10px;

      a,
      button {
        width: 100%;
      }
    }
  }
`

export default CollectionPage
