import React, { useState, useEffect } from "react"

import { forward, backward } from "../../../utils/imageUpload"
import Image from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import BodyWrapper from "../../BodyWrapper"
import bgColors from "./bgColors"

const DiscoverProduct = ({ currentProduct, handleCollection, productArr }) => {
  const [current, setcurrent] = useState(0)
  const [productImgIndex, setproductImgIndex] = useState(1)
  const [bgColor, setbgColor] = useState({
    color: "var(--beige)",
    titleColor: "var(--pink)",
  })

  const setBackground = (colorArr, curr) => {
    const match = colorArr.filter(el => {
      return el.title.toLowerCase() === curr.title.toLowerCase()
    })

    if (match[0]) return match[0]
    else return { color: "var(--beige)", titleColor: "var(--pink)" }
  }

  useEffect(() => {
    setbgColor(setBackground(bgColors, currentProduct))
  }, [current])

  const changeImg = bool => {
    if (bool) {
      setproductImgIndex(0)
    } else {
      setproductImgIndex(1)
    }
  }

  const setIndex = direction => {
    if (direction === "back" && current > 0) {
      setcurrent(current - 1)
    } else if (direction === "forward" && current < productArr.length - 1) {
      setcurrent(current + 1)
    }
  }

  // console.log(bgColor.color)

  return (
    <ProductContainer bg={bgColor.color} titleColor={bgColor.titleColor}>
      <div className="wrapper ">
        <div className="assets-div">
          {/*   <Button mobile onClick={() => setIndex("back")}>
          <img width="15px" src={backward} alt="" />{" "}
        </Button> */}
          <ImgContainer
            onMouseOver={() => changeImg(true)}
            onMouseOut={() => changeImg(false)}
          >
            <Image
              fluid={
                currentProduct.images?.[productImgIndex]?.localFile
                  ?.childImageSharp?.fluid
              }
              alt=""
            />
          </ImgContainer>
          {/*      <Button mobile onClick={() => setIndex("forward")}>
          <img width="15px" src={forward} alt="" />
        </Button> */}
        </div>
        <div className="info-div">
          <h1 className="title">{currentProduct.title}</h1>
          <p className="description">{currentProduct.description}</p>
          <div className="buttonIcon-div">
            <div className="description-icons-div">
              {currentProduct.images.map((img, i) => {
                if (i > 1 && i < 5) {
                  return (
                    <div key={i} className="description-icons">
                      <Image
                        fluid={
                          currentProduct.images?.[i]?.localFile?.childImageSharp
                            ?.fluid
                        }
                        alt=""
                      />
                    </div>
                  )
                }
              })}
            </div>
            <div className="button-container-product">
              {handleCollection?.title == "Cookies" ||
              currentProduct.productType.includes("waffels") ? (
                <Link to={`/product/${currentProduct.handle.slice(0, -2)}`}>
                  <button className="shop-btn">Shop now</button>
                </Link>
              ) : handleCollection?.title == "Desserts" ? (
                <Link to="/product/frozen-desserts">
                  <button className="shop-btn">Shop now</button>
                </Link>
              ) : (
                <Link to="/product/frozen-breakfast">
                  <button className="shop-btn">Shop now</button>
                </Link>
              )}
              <Link to="/store-locator">
                <button className="shop-btn where-btn">Where to buy</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ProductContainer>
  )
}

export default DiscoverProduct

const ImgContainer = styled.div`
  width: 500px;
  height: 500px;
  @media (min-width: 1500px) {
    height: 350px;
    width: 350px;
  }

  @media (max-width: 1024px) {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Button = styled.div`
  margin: 0 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid var(--darkBlue);

  :hover {
    cursor: pointer;
  }

  display: ${props => (props.mobile ? "none" : "")};

  @media (max-width: 1024px) {
    display: ${props => (props.desktop ? "none" : "inherit")};
  }
`

const ProductContainer = styled.div`
  background-color: ${props => props.bg};
  padding: 50px 0;

  .shop-btn {
    background-color: ${props =>
      //console.log(props.bg) ||
      props.bg === "#e81f76" ? "white" : "var(--pink)"};
    color: ${props => (props.bg === "#e81f76" ? "var(--pink)" : "white")};
  }
  // width: 100%;
  .wrapper {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    // margin: 50px 0;
    // align-items: flex-start;
    max-width: var(--hugeInNav);
    margin: 0 auto;
  }

  .assets-div {
    // border: 1px solid red;
    display: flex;
    align-items: center;

    justify-content: center;
  }

  .info-div {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // border: 1px solid red;
    @media (min-width: 1500px) {
      /* width: 400px; */
    }
  }

  .title {
    color: ${props => props.titleColor};

    text-transform: uppercase;
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .price {
    color: var(--pink);
    font-weight: bolder;
    margin: 0.67em 0;
  }

  .description {
    color: var(--darkBlue);
    font-weight: 500;
    margin: 2em 0;
  }

  .btn-div {
    display: flex;
    width: 45%;
    backround: white;
    border-radius: 20px;
    color: var(--pink);
    border: 2px solid var(--pink);
    align-items: center;
    font-weight: bolder;
    justify-content: center;

    p {
      margin: 0;
    }

    button {
      width: 40%;
      padding: 15px 12px;
      background: transparent;
      color: var(--pink);
      border: none;
      font-weight: bolder;
    }
  }

  .button-container-product {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    button {
      width: 150px;

      font-size: 0.9em;
      font-weight: bolder;
      text-transform: uppercase;
      border: none;
      border-radius: 20px;
      padding: 15px 25px;

      margin: 10px 0;
    }

    .where-btn {
      background: transparent;
      color: var(--darkBlue);
      text-decoration: underline;
    }
  }

  .buttonIcon-div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .description-icons-div {
    display: flex;

    justify-content: space-evenly;
    width: 100%;
    margin-right: 10px;
  }

  .description-icons {
    width: 100%;
    height: 110px;
    // margin: 0 10px;

    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;

      img {
        object-fit: contain !important;
      }
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      flex-direction: column;
      text-align: center;
    }
    .assets-div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .gatsby-image-wrapper {
      // height: 100%;
      width: 100%;
    }
    .info-div {
      margin: 0 auto;
      width: 90%;
    }

    .description-icons-div {
      justify-content: space-evenly;
      margin-right: 0;
    }
    .buttonIcon-div {
      flex-direction: column;
    }

    .button-container-product {
      align-items: center;
      margin-top: 10px;

      a,
      button {
        width: 100%;
      }
    }
  }
`
