const bgColors = [
  {
    title: "COOKIES & CREAM CHEESECAKE",
    color: "#54c0e8",
    titleColor: "var(--white)",
  },
  {
    title: "RASPBERRY CHEESECAKE",
    color: "#f97fb5",
    titleColor: "var(--white)",
  },
  {
    title: "KEY LIME CHEESECAKE",
    color: "#fed925",
    titleColor: "var(--darkBlue)",
  },
  {
    title: "NEW YORK CHEESECAKE",
    color: "#00a7e1",
    titleColor: "var(--white)",
  },
  {
    title: "COOKIE BUTTER CHEESECAKE",
    color: "#ffeddb",
    titleColor: "var(--pink)",
  },

  {
    title: "SALTED CARAMEL LAVA CAKE",
    color: "#e81f76",
    titleColor: "var(--white)",
  },
  {
    title: "DARK CHOCOLATE LAVA CAKE",
    color: "#b11cab",
    titleColor: "var(--white)",
  },
]

export default bgColors
